export default {
  name: null,
  email: null,
  userId: null,
  userRoleId: null,
  country: "",
  agencyId: null,
  agencyName: null,
  agencyType: null,
  secondaryEmail: null,
  canSendRequests: null,
  isTravelOperator: null,
  clientCountryCode: null,
  clientCountryName: null,
};
